<template>
  <v-card>
    <v-alert
      :value="createdSuccess"
      type="success"
      class="my-0"
    >Смена клиента выполнена</v-alert>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card-title
        class="px-4"
      >
        <span class="headline">Изменение синхронизации в букете №{{ id }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-4"
      >
        <v-checkbox
          v-model="editedItem.b24_bouquet_show_active"
          label="Выбрать букет на сайте"
        ></v-checkbox>

        <v-autocomplete
          label="Имя букета"
          :items="b24BouquetList"
          item-text="name"
          item-value="id"
          v-if="editedItem.b24_bouquet_show_active"
          v-model.number="editedItem.bit_element_id"
          hide-details
          no-data-text="Не надено"
          clearable
          :search-input.sync="searchB24Bouquet"
        ></v-autocomplete>

        <v-autocomplete
          :key="editedItem.bit_category_id.join('-')"
          clearable
          chips
          item-text="name"
          item-value="id"
          label="Выбрать категорию"
          v-model="editedItem.bit_category_id"
          :items="flattenedCategories"
          hide-details
          no-data-text="Не надено"
          multiple
        ></v-autocomplete>

        <v-text-field
          v-model.number="editedItem.sort"
          label="Сортировка"
          type="number"
          :rules="[v => !!v || 'Заполните поле']"
        ></v-text-field>

        <v-text-field
          v-model="editedItem.bouquet_name"
          v-if="!editedItem.b24_bouquet_show_active"
          label="Название букета на сайте"
          :rules="[v => !!v || 'Заполните поле']"
        ></v-text-field>

        <v-text-field
          v-model.number="editedItem.stock"
          label="Доступный остаток"
          type="number"
          :rules="[v => !!v || 'Заполните поле']"
        ></v-text-field>

        <v-checkbox
          v-model="editedItem.sync_is_active"
          label="Выгрузка на сайт"
        ></v-checkbox>
        <p v-if="editedItem.path">
          <!-- Render a link if code is not empty -->
          <a :href="generateLink(editedItem.path)" target="_blank" rel="noopener noreferrer">B24 Bouquet</a>
        </p>
      </v-card-text>
      <v-card-actions
        class="px-4 pb-4"
      >
        <v-btn
          @click.native="cancel()"
        >Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="submitForm"
        >Сохранить</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      b24BouquetList: [],
      searchB24Bouquet:'',
      timerBouquet: null,
      categories: [],
      editedItem: {
        b24_bouquet_show_active: false,
        bit_element_id: 0,
        bit_category_id: [],
        sync_is_active: false,
        sort: 0,
        bouquet_name: '',
        stock: 0,
      },
      createdSuccess: false,
    };
  },
  watch: {
    'editedItem.bit_element_id': function(newVal, oldVal) {
      // Check if the selected bouquet has a bit_category_id property
      const selectedBouquet = this.b24BouquetList.find(item => item.id === newVal);
      this.getB24Bouquets(newVal);
      if (selectedBouquet) {
        // Set the bit_category_id in editedItem
        this.editedItem.bit_category_id = selectedBouquet.bit_category_id;
      } else {
        // Clear the bit_category_id if not available
        // this.editedItem.bit_category_id = [];
      }
    },
    // deep: true,
    searchB24Bouquet(val) {
      const findB24Bouquet = this.b24BouquetList.find((item) => item.name === val);
      if (findB24Bouquet) return false;

      if (val && val.length >= 3) {
        if (this.timerBouquet) clearTimeout(this.timerBouquet);

        this.timerBouquet = setTimeout(() => {
          this.getB24Bouquets(val);
        }, 500);
      } else {
        this.b24BouquetList = [];
      }
    },
  },
  computed: {
    flattenedCategories() {
      return this.flattenTree(this.categories);
    },
  },
  mounted() {
    this.getCategoryList();
    this.getBouquet();
  },
  methods: {
    getB24Bouquets(searchVal) {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bit-bouquet`;

      axios
        .get(url, {
          params: {
            id_or_name: searchVal,
          },
        })
        .then((response) => {
          this.b24BouquetList = response.data.map((item) => {
            return {
              name: `${item.BouquetName} (${item.ID})`,
              id: item.ID,
              bit_category_id: item.bitCategoryID
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateLink(path) {
      return `https://buket-ug.ru/catalog/${path}`;
    },
    getBouquet() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bouquets/${this.id}`;
      axios.get(url)
        .then(response => {
          if (response.data && response.data.bouquet_sync !== null){
            this.editedItem = response.data.bouquet_sync;
          }
        })
        .catch(error => {
          console.error('Error fetching bouquet details:', error);
        });
    },
    getCategoryList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bit-category`;

      axios.get(url)
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => {
          console.error('Error fetching category list:', error);
        });
    },
    flattenTree(tree) {
      let flattened = [];
      function traverse(node) {
        // Add the ID and name of the current node to the flattened array
        flattened.push({
          id: node.ID,
          name: node.NAME,
        });

        if (node.Children && node.Children.length > 0) {
          // If there are children, recursively traverse them and add their ID and name
          node.Children.forEach(child => {
            traverse(child);
          });
        }
      }

      // Start traversal from the root of the tree
      this.categories.forEach(rootNode => {
        traverse(rootNode);
      });

      return flattened;
    },
    cancel() {
      this.editedItem = {};
      this.createdSuccess = false;
      this.$emit('cancel');
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      
      if (validate && this.editedItem.bit_category_id.length > 0 && this.editedItem.sort !=0 && this.editedItem.stock !=0) {
        // const propsItem = Object.assign({}, this.editedItem);
        const propsItem = {
          b24_bouquet_show_active: this.editedItem.b24_bouquet_show_active,
          bit_element_id: this.editedItem.bit_element_id,
          bit_category_id: this.editedItem.bit_category_id,
          sync_is_active: this.editedItem.sync_is_active,
          sort: this.editedItem.sort,
          bouquet_name: this.editedItem.bouquet_name,
          stock: this.editedItem.stock,
        };

         // if sync_is_active is false, bit_element_id = 0
         if(!this.editedItem.sync_is_active){
          propsItem.bit_element_id = 0
        }

        // if b24_bouquet_show_active is true, bouquet_name is null 
        if(this.editedItem.b24_bouquet_show_active == true){
          propsItem.bouquet_name = null
          // alert(1)
          console.log('propsItem.bouquet_name', propsItem.bouquet_name)
        } 
        console.log(propsItem)
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bouquets-sync/${this.id}`;

        axios
          .post(url, propsItem)
          .then(() => {
            this.createdSuccess = true;

            setTimeout(() => {
              this.$emit('cancel');
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log('failed')
      }
    },
  },
};
</script>
